import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

// const menuData = [
//   { title: "About Me", link: "/page-2" },
//   { title: "Blog", link: "/page-2" },
// ]

export default function Footer() {
  return (
    <KingWrapper>
      <Wrapper>
        <Logo>
          <Link to="/">
            <img src="/images/logos/logo.svg" alt="logo" />
          </Link>
        </Logo>
        {/* <MenuWrapper>
          {menuData.map((item, index) => (
            <Link to={item.link} key={index}>
              <MenuItem>{item.title}</MenuItem>
            </Link>
          ))}
        </MenuWrapper> */}
      </Wrapper>
    </KingWrapper>
  )
}

const KingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 300px;
`

const Wrapper = styled.div`
  width: 100%;
  bottom: 100px;
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  align-content: center;
`
const Logo = styled.div`
  padding-bottom: 48px;
`

// const MenuWrapper = styled.div`
//   display: grid;
//   gap: 30px;
//   grid-template-columns: repeat(2, auto);
// `
// const MenuItem = styled.div`
//   font-style: normal;
//   font-weight: 500;
//   font-size: 15px;
//   line-height: 18px;
//   color: #91932d;
// `
